import React from 'react';
import { Link } from 'react-router-dom';
import Tilt from 'react-parallax-tilt';
const HomeHeroArea = () => {
   return (
      <React.Fragment>
         <div className='hero__area' id="home">
      <div className='shape-one'></div>
      <div className='shape-two'></div>
      <div className='container h-100 align-self-center '>
     
         <div className='row  h-100 justify-content-center'>
            <div className='col-md-7 align-self-center order-2 order-md-1'>
               <div className='hero_content'>
                  <div className='herotext'>
                     <h1 className="slider-title">Welcome to <span className="under-line style-two   under-line-green">
                        DriveIT </span> <br/> your trusted partner <br/>
                        in business success
                     </h1>
                     <p className="slider-short-desc">At DriveIT- Recruitment, we believe that recruitment is not just about filling positions but building relationships.</p>
                     <div className='slider-btn-wrapper'>
                        <a href="tel:+91-9717834009" className="slider-btn active" tabindex="0">Schedule A Call Today</a>                   
                        <a href="#contact" className="slider-btn" >
                        Contact Us</a>
                     </div>
                  </div>
               </div>
            </div>
           
            <div className='col-md-5 align-self-center order-1 order-md-2 d-lg-block d-none'>
            <Tilt>
               <div className='banner_img'>
                  <div className="sketch" ></div>
                  <div className="brush-sketch"></div>                  
                  <img src='assets/img/slider_img_4.png' alt='' />
               </div>
               </Tilt>
            </div>
           
         </div>
         
      </div>
   </div>

   {/* <div className='rotating_wheel'>
      <div className="scrolldown-btn-wrapper">
            <a href="#about_us" id="scrolldown" className="scrolldown-btn">   
            <img src='assets/img/arrow-down.svg' className='down_arrow'  alt='' />
                <div className="rounded-text rotating">
                <img src='assets/img/scroll-rotate.png' alt='' />
                </div>
            </a>
        </div>
   </div> */}




       </React.Fragment>
   );
};

export default HomeHeroArea;